<template>
  <b-row>
    <b-col
      cols="12"
      md="3"
    >
      <reminder-customer-view />
    </b-col>
    <b-col
      cols="12"
      md="9"
    >
      <view-reminder-card :get-data-refresh="getDataRefresh" />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import ReminderCustomerView from '@/views/Insurance/Reminder/Components/CustomerInfo.vue'
import ViewReminderCard from '@/views/Insurance/Reminder/Components/ViewCard.vue'

export default {
  name: 'ReminderViewCard',
  components: {
    ViewReminderCard,
    ReminderCustomerView,
    BRow,
    BCol,
  },
  props: {
    getDataRefresh: {
      type: Function,
      required: true,
    },
  },
}
</script>
