<template>
  <b-form-group
    label="Görüşme Notu"
    label-for="content"
  >
    <validation-provider
      #default="{ errors }"
      name="Görüşme Notu"
      rules="required"
    >
      <b-form-textarea
        v-model="interviewLine.content"
        placeholder="Görüşme Notu"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'Content',
  components: {
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    interviewLine() {
      return this.$store.getters['insuranceReminder/interviewLine']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped>

</style>
