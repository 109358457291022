<template>
  <b-card>
    <b-row>
      <b-col>
        <item-statistics
          title="Günlük Arama"
          :value="Number(istatistics.day_calls)"
          color="light-success"
          icon="PhoneCallIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Günlük Arama Hedefi"
          :value="Number(istatistics.day_call_target)"
          color="light-info"
          icon="FlagIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Aylık Arama"
          :value="Number(istatistics.month_calls)"
          color="light-success"
          icon="PhoneCallIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Aylık Arama Hedefi"
          :value="Number(istatistics.month_call_target)"
          color="light-danger"
          icon="FlagIcon"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import ItemStatistics from '@/views/Crm/components/ItemStatistics.vue'

export default {
  name: 'InsuranceReminderStatistics',
  components: {
    BCol, BRow, ItemStatistics, BCard,
  },
  computed: {
    istatistics() {
      return this.$store.getters['insuranceReminder/getIstatistics']
    },
  },
}
</script>
