<template>
  <div>
    <b-list-group>
      <list-item
        title="Adı Soyadı"
        :value="detailModal.customer"
      />
      <list-item
        title="TCKN / Vergi No"
        :value="detailModal.hes_kod"
      />
    </b-list-group>
  </div>
</template>
<script>
import { BListGroup } from 'bootstrap-vue'
import ListItem from '@/views/Customers/view/ListItem.vue'

export default {
  name: 'ReminderCustomerView',
  components: { ListItem, BListGroup },
  computed: {
    dataList() {
      return this.$store.getters['insuranceReminder/getDataList']
    },
    detailModal() {
      return this.$store.getters['insuranceReminder/getDetailModal']
    },
  },
}
</script>
