<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="ListIcon" />
          <span>Poliçe Listesi</span>
        </template>
        <reminder-list />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="MessageCircleIcon" />
          <span>Görüşme Bilgisi</span>
        </template>
        <reminder-interview-card :get-data-refresh="getDataRefresh" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { BTab, BTabs } from 'bootstrap-vue'
import ReminderList from '@/views/Insurance/Reminder/Components/View/PoliceList.vue'
import ReminderInterviewCard from '@/views/Insurance/Reminder/Components/View/interviewCard.vue'

export default {
  name: 'ViewReminderCard',
  components: {
    ReminderInterviewCard, ReminderList, BTab, BTabs,
  },
  props: {
    getDataRefresh: {
      type: Function,
      required: true,
    },
  },

}
</script>
