<template>
  <b-form-group
    label="TC / Vergi No"
    label-for="tckn"
  >
    <validation-provider
      #default="{ errors }"
      name="TC / Vergi No"
      rules="required"
    >
      <b-form-input
        id="tckn"
        v-model="interviewLine.tckn"
        placeholder="TC / Vergi No"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'Tckn',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    interviewLine() {
      return this.$store.getters['insuranceReminder/interviewLine']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
