<template>
  <div>
    <b-card title="Dönem Seçimi">
      <b-row class="d-flex align-content-center align-items-center">
        <b-col>
          <b-form-group
            label="Hatırlatma Dönemi"
            label-for="month"
          >
            <v-select
              id="month"
              v-model="dataQuery.params.month"
              :options="months"
              :reduce="month => month.id"
              label="title"
              placeholder="Dönem Seçiniz"
              @input="selectMonth"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Durum"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="dataQuery.params.status"
              :options="statuses"
              :reduce="status => status.id"
              label="title"
              placeholder="Durum Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Gün"
            label-for="today"
          >
            <b-form-datepicker
              id="today"
              :key="datePicker.key"
              v-model="dataQuery.params.today"
              v-bind="{labelNoDateSelected: 'Gün',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
              :min="datePicker.minDate"
              :max="datePicker.maxDate"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Danışman"
            label-for="id_com_user"
          >
            <v-select
              id="id_com_user"
              v-model="dataQuery.params.id_com_user"
              :options="users"
              :reduce="user => user.id"
              label="title"
              placeholder="Seçiniz"
              :disabled="userPicker.disabled"
            >
              <template v-slot:option="option">
                <div v-if="option.user_type">
                  {{ option.title }}
                  <div>
                    <small class="text-warning">{{ option.user_type }}</small>
                  </div>
                </div>
                <div v-else>
                  {{ option.title }}
                </div>
              </template>
              <template v-slot:selected-option="option">
                {{ option.title }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="text-center"
        >
          <b-button
            variant="primary"
            :disabled="!dataQuery.params.today || !dataQuery.params.status || !dataQuery.params.id_com_user"
            @click="filterData"
          >
            Verileri Getir
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <insurance-reminder-statistics />
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Müşteri Hatırlatma Listesi
        </b-card-title>
        <div class="w-25">
          <b-input-group>
            <b-form-input
              id="customer"
              v-model="dataQuery.params.customer"
              placeholder="Müşteri Ara.."
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="searchData"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>

      </b-card-header>
      <template v-if="dataList.length > 0">
        <b-table

          :fields="fields"
          :items="dataList"
          responsive="sm"
          striped
        >
          <template #cell(customer)="data">
            {{ data.item.customer }}
            <div>
              <template v-if="!data.item.id_com_interview">
                <small class="text-warning">Arama Bekliyor</small>
              </template>
              <template v-if="data.item.id_com_interview">
                <b-link
                  class="font-small-2 font-weight-bold"
                  :to="'/interviews/view/'+data.item.interview_number"
                  target="_blank"
                >
                  Görüşmeyi görüntüle
                </b-link>
              </template>
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="primary"
              size="sm"
              @click="showDetailModal(data.item)"
            >
              Görüntüle
            </b-button>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </template>
      <b-alert
        v-else
        show
        variant="info"
        class="m-2"
      >
        <div class="alert-body text-center">
          Seçili güne ait uygun kayıt bulunmamakta. 👏
        </div>
      </b-alert>
    </b-card>
    <b-modal
      v-model="detailModal.status"
      hide-footer
      title="Görüntüle"
      size="xl"
      centered
      no-close-on-esc
      no-close-on-backdrop
    >
      <reminder-view-card :get-data-refresh="getData" />
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BButton,
  BLink,
  BModal,
  BFormDatepicker,
  BAlert,
  BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BCardFooter, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import InsuranceReminderStatistics from '@/views/Insurance/Reminder/Components/IstaticticsCard.vue'
import ReminderViewCard from '@/views/Insurance/Reminder/Components/ViewModalCard.vue'

export default {
  name: 'InsuranceReminder',
  components: {
    BPagination,
    BCardFooter,
    BFormInput,
    BAlert,
    BFormDatepicker,
    ReminderViewCard,
    vSelect,
    BTable,
    InsuranceReminderStatistics,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BLink,
    BModal,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      datePicker: {
        visible: true,
        minDate: null,
        maxDate: null,
        key: 0,
      },
      currentPage: 1,
      perPage: 25,
      userPicker: {
        disabled: false,
      },
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
        },
        {
          key: 'username',
          label: 'Danışman',
          thStyle: { width: '200px' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '200px' },
        },
      ],
      statuses: [
        {
          id: 2,
          title: 'Aranmamışlar',
        },
        {
          id: 1,
          title: 'Aranmışlar',
        },
      ],
      dataQuery: {
        select: [
          'mds.hes_ad                                AS customer',
          'mds.hes_kod                               AS hes_kod',
          'count(mds.id)                             AS callCount',
          'com_insurance_reminder.poli_id            AS poli_id',
          'com_insurance_reminder.police_no          AS police_no',
          'com_insurance_reminder.id_com_interview   AS id_com_interview',
          'com_insurance_reminder.id_com_user        AS id_com_user',
          'com_interview.interview_number            AS interview_number',
          'com_user.name                             AS username',
        ],
        limit: 25,
        start: 0,
        params: {
          month: this.moment().format('YYYY-MM'),
          today: this.moment().format('YYYY-MM-DD'),
          status: 2,
          id_com_user: JSON.parse(localStorage.getItem('userData')).id,
          customer: null,
        },
        group_by: 'mds.hes_kod',
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['insuranceReminder/getCallList']
    },
    detailModal() {
      return this.$store.getters['insuranceReminder/getDetailModal']
    },
    istatistics() {
      return this.$store.getters['insuranceReminder/getIstatistics']
    },
    dataCount() {
      return this.$store.getters['insuranceReminder/dataCount']
    },
    months() {
      return this.$store.getters['insuranceReminder/getPeriods']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getData()
    this.getUsers()
  },
  methods: {
    searchData() {
      this.dataQuery.start = null
      this.dataQuery.limit = null
      if (!this.dataQuery.params.customer) {
        this.dataQuery.limit = 25
        this.dataQuery.start = 0
      }
      this.getData()
    },
    filterData() {
      this.dataQuery.limit = 25
      this.dataQuery.start = 0
      this.currentPage = 1
      this.getData()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getData()
      }
    },
    getData() {
      this.$store.dispatch('insuranceReminder/callList', this.dataQuery)
    },
    getUsers() {
      const where = {
        'com_user.id_com_brand': 8,
        'com_user.status': 1,
      }
      if (this.userData.id_com_user_type === '5' && this.userData.id !== '165') {
        where['com_user.id'] = this.userData.id
        this.userPicker.disabled = true
      }
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id          AS id',
          'com_user.name        AS title',
          'com_user_type.title  AS user_type',
        ],
        where,
      })
    },
    showDetailModal(data) {
      this.detailModal.status = true
      this.detailModal.hes_kod = data.hes_kod
      this.detailModal.poli_id = data.poli_id
      this.detailModal.police_no = data.police_no
      this.detailModal.customer = data.customer
      this.$store.dispatch('insuranceReminder/dataList', {
        select: [
          'mds.hes_ad                                AS customer',
          'mds.hes_kod                               AS hes_kod',
          'date_format(mds.bas_tar,"%d.%m.%Y")       AS bas_tar',
          'date_format(mds.bit_tar,"%d.%m.%Y")       AS bit_tar',
          'mds.sirket_adi                            AS sirket_adi',
          'mds.poli_turu                             AS poli_turu',
          'mds.bransadi                              AS bransadi',
          'com_insurance_reminder.id                 AS id',
          'com_insurance_reminder.call_date          AS call_date',
          'com_insurance_reminder.poli_id            AS poli_id',
          'com_insurance_reminder.police_no          AS police_no',
          'com_insurance_reminder.id_com_interview   AS id_com_interview',
          'com_interview.interview_number            AS interview_number',
          'com_insurance_reminder.id_com_user        AS id_com_user',
          'com_user.name                             AS username',
        ],
        where: {
          'com_insurance_reminder.hes_kod': data.hes_kod,
          'com_insurance_reminder.id_com_user': data.id_com_user,
        },
      })
    },
    selectMonth() {
      this.datePicker.visible = false
      this.dataQuery.params.today = `${this.dataQuery.params.month}-01`
      // eslint-disable-next-line radix
      const year = parseInt(this.dataQuery.params.month.substring(0, 4))
      // eslint-disable-next-line radix
      const month = parseInt(this.dataQuery.params.month.substring(5, 7)) - 1
      const daysInMonth = new Date(year, month + 1, 0).getDate()
      this.datePicker.minDate = new Date(year, month, 1)
      this.datePicker.maxDate = new Date(year, month, daysInMonth)
      this.datePicker.key += 1
    },
  },
}
</script>
