<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Görüşme Tarihi"
          label-for="idate"
        >
          <b-form-datepicker
            id="idate"
            v-model="interviewLine.idate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Görüşme Saati"
          label-for="itime"
        >
          <b-form-timepicker
            id="itime"
            v-model="interviewLine.itime"
            :locale="locale"
            v-bind="labels[locale] || {}"
            @context="onContext"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormDatepicker,
  BFormTimepicker,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'DateTime',
  components: {
    BFormGroup,
    BFormDatepicker,
    BFormTimepicker,
    BRow,
    BCol,
  },
  data() {
    return {
      locale: 'tr',
      locales: [
        { value: 'en-US', text: 'English US (en-US)' },
        { value: 'tr', text: 'Türkçe (tr)' },
      ],
      labels: {
        tr: {
          labelHours: 'Saat',
          labelMinutes: 'Dakika',
          labelSeconds: 'Saniye',
          labelIncrement: 'Arttır',
          labelDecrement: 'Azalt',
          labelSelected: 'Seçildi',
          labelNoTimeSelected: 'Saat Seçiniz',
          labelCloseButton: 'Seç',
        },
      },
    }
  },
  computed: {
    interviewLine() {
      return this.$store.getters['insuranceReminder/interviewLine']
    },
  },
  methods: {
    onContext(ctx) {
      this.context = ctx
    },
  },
}
</script>

<style scoped>

</style>
