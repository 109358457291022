<template>
  <div>
    <pre-loading v-if="dataLoading" />
    <b-table
      v-else
      :fields="fields"
      :items="dataList"
      responsive="sm"
      striped
      small
    >
      <template #cell(customer)="data">
        {{ data.item.customer }}
        <div>
          <small><b>Poliçe No:</b> <span class="text-warning">{{ data.item.police_no }}</span></small>
        </div>
        <div>
          <small>Baş/Bit: <span>{{ data.item.bas_tar }} / {{ data.item.bit_tar }}</span></small>
        </div>
        <div>
          <small class="text-info">{{ data.item.sirket_adi }} / </small>
          <small class="text-info">{{ data.item.poli_turu }} / </small>
          <small class="text-info">{{ data.item.bransadi }}  </small>
        </div>
      </template>
      <template #cell(call_date)="data">
        <div>
          <small :class="data.item.id_com_interview ? ' text-success' : 'text-warning'">{{ moment(data.item.call_date).format('DD.MM.YYYY') }}</small>
        </div>
        <div v-if="data.item.id_com_interview">
          <b-link
            :to="'/interviews/view/' + data.item.interview_number"
            target="_blank"
            class="font-weight-bold"
          >
            Görüşme kaydı mevcut
          </b-link>
        </div>
        <div v-else>
          <small class="text-secondary">Arama Bekliyor</small>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import { BTable, BLink } from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'ReminderList',
  components: { PreLoading, BTable, BLink },
  data() {
    return {
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
        },
        {
          key: 'call_date',
          label: 'Arama Tarihi',
        },
        {
          key: 'username',
          label: 'Danışman',
          thStyle: { width: '200px' },
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['insuranceReminder/getDataList']
    },
    dataLoading() {
      return this.$store.getters['insuranceReminder/dataLoading']
    },
  },
}
</script>
